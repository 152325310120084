/* eslint-disable react/prop-types */
import { Card } from '@mui/material';
import { ViewTitle } from 'components/ViewTitle';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import _, { isEmpty } from 'lodash';
import CalQuotaLeave from '../../function/CalQuotaLeave';
import * as action from '../../redux/action';
import { AddToPhotos, LibraryAddCheck } from '@mui/icons-material';
import dayjs from 'dayjs';
import CalQuotaOption from '../../function/CalQuotaOption';
import CalRemain from '../../function/CalRemain';

export default function Leave({ title, subtitle }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const leaveDoc = useSelector((state) => state.leaveDoc);
  const leaveType = useSelector((state) => state.leaveType);
  const access = me?.userData?.position?.access;
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  useEffect(() => {
    dispatch(action.getAllLeaveDoc({ name: me?.userData?.name }));
    dispatch(action.leaveTypeGetAll({}));
  }, []);
  console.log('me', me);
  // const currentDate = dayjs().locale('th').format('YYYY-MM-DDTHH:mm:ssZ[Z]');
  const currentDate = dayjs().locale('th').startOf('day');
  console.log('currentDate', currentDate);
  const datePass = dayjs(me?.userData?.datePass);
  console.log('currentDate', currentDate);
  const dayWork = currentDate.diff(datePass, 'day');
  console.log('dayDifference', dayWork);

  const renderCard = () => (
    <div className="">
      <div className=" lg:flex gap-2 py-1 ">
        <div className=" lg:w-1/2 p-2 ">
          <Card>
            <Link to="/work/leave-work/apply">
              <div className="bg-blue-600  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                <div className="flex justify-center">
                  {/* <Clock size={50} color="white" /> */}
                </div>
                <div>
                  <AddToPhotos
                    className="text-white text-9xl "
                    sx={{ fontSize: 50 }}
                  />
                  <p className=" text-white text-2xl mt-4">ยื่นใบลา</p>
                </div>
              </div>
            </Link>
          </Card>
        </div>
        {access?.APPLOVE_LEAVEDOC && (
          <div className="lg:w-1/2 p-2">
            <Card>
              <Link to="/work/leave-work/confirm">
                <div className="bg-green-600  shadow rounded text-center  py-8 px-4 lg:h-40  transition-transform transform hover:scale-105">
                  <div className="flex justify-center">
                    {/* <Clock size={50} color="white" /> */}
                  </div>
                  <LibraryAddCheck
                    className="text-white text-9xl "
                    sx={{ fontSize: 50 }}
                  />
                  <p className=" text-white text-2xl mt-4">อนุมัติใบลา</p>
                </div>
              </Link>
            </Card>
          </div>
        )}
      </div>
    </div>
  );

  const renderQuotaDetail = () => (
    <div className="">
      <Card>
        <div className="p-2 ">
          <div className="font-bold text-l ">อายุงาน:{dayWork} วัน</div>
          <div className="font-bold pb-4">สรุปข้อมูลวันลา</div>
          <div className="lg:grid lg:grid-cols-5 gap-2 gird-cols-2 ">
            {_.map(CalQuotaLeave(leaveType, leaveDoc), (data, index) => (
              <div
                className=" border-2 border-green-700 rounded-md"
                key={index}
              >
                <Card>
                  <div className="mx-2  my-2  pb-6 px-2 ">
                    <div className="font-bold text-green-700 text-center ">
                      {data?._id?.name || data?.name}
                    </div>

                    <div className="flex py-2 pr-2 justify-center ">
                      <div className=" font-bold ">คงเหลือ</div>
                      {/* {data?.remain >= 0 ? data?.remain : data?.quantity} */}
                      {console.log('data----------', data)}
                      <div className="pl-2">{CalRemain(data, me)}</div>
                      วัน
                    </div>

                    <div className="flex justify-center">
                      <div className="  font-bold">ใช้ไป</div>
                      <div className="pl-2">{data?.sum || 0} วัน</div>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </div>
  );

  return (
    <div>
      {renderTitle()}
      {/* {renderDetail()} */}
      {renderQuotaDetail()}
      {renderCard()}
    </div>
  );
}
