/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PortraitSharpIcon from '@mui/icons-material/PortraitSharp';
import StoreIcon from '@mui/icons-material/Store';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import logoImage from '../../../assets/img/logopng.png';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WorkIcon from '@mui/icons-material/Work';
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../../../redux/action';
import Navbar from '../Navbars/AdminNavbar';
import { HomeNavbar } from 'components/Navbars';
import SettingsIcon from '@mui/icons-material/Settings';
import NoteIcon from '@mui/icons-material/Note';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Avatar, Box, Card, Typography } from '@mui/material';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
export default function Sidebar({ setMobileNavOpen }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const setting = useSelector((state) => state.setting);
  const me = useSelector((state) => state.me);
  const access = me?.userData?.position?.access;
  const module = setting?.arr;

  useEffect(() => {
    dispatch(action.settingGetAll({}));
    return () => {};
  }, []);

  const [collapseShow, setCollapseShow] = React.useState('hidden');
  const [openPo, setOpenPo] = React.useState(
    _.includes(window.location.href, 'purchase')
  );
  const [open, setOpen] = React.useState(
    _.includes(window.location.href, 'sale')
  );
  const [openHr, setOpenHr] = React.useState(
    _.includes(window.location.href, 'hr')
  );
  const [openSubHr, setOpenSubHr] = React.useState(
    _.includes(window.location.href, 'hr/setting')
  );
  const [openFi, setOpenFi] = React.useState(
    _.includes(window.location.href, 'salary')
  );
  const [openAD, setOpenAD] = React.useState(
    _.includes(window.location.href, 'admin')
  );
  const [openWork, setOpenWork] = React.useState(
    _.includes(window.location.href, 'work')
  );

  const onSignOut = async () => {
    await localStorage.removeItem('userId');
    localStorage.removeItem('Token');
    history.push('/');
  };

  const handleClick = () => {
    setOpen(!open);
  };
  const handlePoClick = () => {
    setOpenPo(!openPo);
  };
  const handleHrClick = () => {
    setOpenHr(!openHr);
  };
  const handleSubHrClick = () => {
    setOpenSubHr(!openSubHr);
  };
  const handleFiClick = () => {
    setOpenFi(!openFi);
  };
  const handleADClick = () => {
    setOpenAD(!openAD);
  };
  const handleWorkClick = () => {
    setOpenWork(!openWork);
  };
  return (
    <div>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 mt-6 md:mt-20 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto ">
          {/* Toggler */}
          <div className=" w-full flex justify-between">
            <div className="md:hidden font-bold text-green-600">
              {information?.name}
            </div>
            <button
              className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              type="button"
              onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          {/* Brand */}

          <div className="hidden md:block">
            <Box>
              <div className="p-2 rounded-xl flex flex-wrap justify-center bg-gray-200">
                <div className="lg:w-1/3 flex justify-center ">
                  <div className="bg-gray-300 rounded-full p-1">
                    <Avatar
                      alt={me?.userData?.name}
                      src={me?.userData?.image?.url}
                    ></Avatar>
                  </div>
                </div>
                <div className="w-full lg:w-2/3 text-center">
                  <Typography color="textPrimary" variant="body2">
                    {me?.userData?.name} {me?.userData?.surname}
                  </Typography>
                  <Typography color="palevioletred" variant="body2">
                    {me?.userData?.department?.name}
                  </Typography>
                </div>
              </div>
            </Box>
          </div>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative"></li>
            <li className="inline-block relative"></li>
          </ul>
          {/* Collapse */}
          <div
            className={
              'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0"
                    to="/home"
                  >
                    E-COMPANY
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow('hidden')}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="px-3 py-2 h-12 border border-solid  border-gray-600 placeholder-gray-400 text-gray-700 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form>
            {/* Divider */}
            <hr className="my-2 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1  no-underline"></h6>
            <List
              sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}
            >
              {access?.TIMESTAMP_SETTING && (
                <Link
                  className={
                    'text-l uppercase   block ' +
                    (window.location.href.indexOf(
                      '/work/setting/timestamp-setting'
                    ) !== -1
                      ? 'text-green-700 hover:text-green-700'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to="/work/setting/timestamp-setting"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    ตั้งค่าเวลางาน
                  </ListItemButton>
                </Link>
              )}

              <Link
                className={
                  'text-l uppercase   block ' +
                  (window.location.href.indexOf('/work/checkIn') !== -1
                    ? 'text-green-700 hover:text-green-700'
                    : 'text-gray-800 hover:text-gray-600')
                }
                to="/work/checkIn"
              >
                <ListItemButton>
                  <ListItemIcon>
                    <AccessTimeIcon />
                  </ListItemIcon>
                  ลงเวลา
                </ListItemButton>
              </Link>

              {module?.calendarAll && (
                <Link
                  className={
                    'text-l uppercase   block ' +
                    (window.location.href.indexOf('/work/calendar') !== -1
                      ? 'text-green-700 hover:text-green-700'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to="/work/calendar"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <CalendarMonthIcon />
                    </ListItemIcon>
                    ตารางงาน
                  </ListItemButton>
                </Link>
              )}
              {module?.calendarMe && (
                <Link
                  className={
                    'text-l uppercase   block ' +
                    (window.location.href.indexOf('/work/calendar-me') !== -1
                      ? 'text-green-700 hover:text-green-700'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to="/work/calendar-me"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <CalendarMonthIcon />
                    </ListItemIcon>
                    ตารางงานส่วนบุคคล
                  </ListItemButton>
                </Link>
              )}

              <Link
                className={
                  'text-l uppercase   block ' +
                  (window.location.href.indexOf('/work/leave-work') !== -1
                    ? 'text-green-700 hover:text-green-700'
                    : 'text-gray-800 hover:text-gray-600')
                }
                to="/work/leave-work"
              >
                <ListItemButton>
                  <ListItemIcon>
                    <NoteIcon />
                  </ListItemIcon>
                  การลาพนักงาน
                </ListItemButton>
              </Link>

              {me.userData?.department?.access?.PCD && (
                <div>
                  {/* จัดซื้อ */}
                  <ListItemButton onClick={handlePoClick}>
                    <ListItemIcon>
                      <LocalMallIcon />
                    </ListItemIcon>
                    <ListItemText primary="แผนกจัดซื้อ" />
                    {openPo ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>

                  <Collapse in={openPo} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <Link
                        className={
                          'text-s uppercase  block ' +
                          (window.location.href.indexOf('/purchase/order') !==
                          -1
                            ? 'text-green-700 hover:text-green-700'
                            : 'text-gray-800 hover:text-gray-600')
                        }
                        to="/purchase/order"
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText primary="ใบซื้อสินค้า" />
                        </ListItemButton>{' '}
                      </Link>
                      <Link
                        className={
                          'text-l uppercase   block ' +
                          (window.location.href.indexOf(
                            '/purchase/pickUpOrder'
                          ) !== -1
                            ? 'text-green-700 hover:text-green-700'
                            : 'text-gray-800 hover:text-gray-600')
                        }
                        to="/purchase/pickUpOrder"
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          ใบรับสินค้า
                        </ListItemButton>
                      </Link>
                    </List>
                  </Collapse>
                </div>
              )}
              {/* ฝ่ายขาย */}

              {me.userData?.department?.access?.SLD && (
                <div>
                  <ListItemButton onClick={() => handleClick()}>
                    <ListItemIcon>
                      <MonetizationOnIcon />
                    </ListItemIcon>
                    <ListItemText> แผนกขาย</ListItemText>
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>

                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <Link
                        className={
                          'text-s uppercase   block ' +
                          (window.location.href.indexOf('/sale/quotation') !==
                          -1
                            ? 'text-green-700 hover:text-green-700'
                            : 'text-gray-800 hover:text-gray-600')
                        }
                        to="/sale/quotation"
                      >
                        <i></i>
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText primary="ใบเสนอราคา" />
                        </ListItemButton>{' '}
                      </Link>
                      <Link
                        className={
                          'text-m uppercase  block ' +
                          (window.location.href.indexOf('/sale/invoice') !== -1
                            ? 'text-green-700 hover:text-green-700'
                            : 'text-gray-800 hover:text-gray-600')
                        }
                        to="/sale/invoice"
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText primary="ใบแจ้งหนี้/ ใบส่งสินค้า" />
                        </ListItemButton>
                      </Link>
                      <Link
                        className={
                          'text-l uppercase   block ' +
                          (window.location.href.indexOf('/sale/receipt') !== -1
                            ? 'text-green-700 hover:text-green-700'
                            : 'text-gray-800 hover:text-gray-600')
                        }
                        to="/sale/receipt"
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          ใบเสร็จรับเงิน
                        </ListItemButton>
                      </Link>
                      <Link
                        className={
                          'text-l uppercase   block ' +
                          (window.location.href.indexOf('/customer') !== -1
                            ? 'text-green-700 hover:text-green-700'
                            : 'text-gray-800 hover:text-gray-600')
                        }
                        to="/customer"
                      >
                        <ListItemButton sx={{ pl: 4 }}>ลูกค้า</ListItemButton>
                      </Link>
                    </List>
                  </Collapse>
                </div>
              )}
              {/* human resource */}

              {me?.userData?.department?.access?.HRD && (
                <div>
                  <ListItemButton onClick={handleHrClick}>
                    <ListItemIcon>
                      <PortraitSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary="แผนกบุคคล" />
                    {openHr ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>

                  <Collapse in={openHr} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {/* <Link
                        className={
                          'text-s uppercase  block ' +
                          (window.location.href.indexOf('/hr/dashboard') !== -1
                            ? 'text-blue-500 hover:text-blue-600'
                            : 'text-gray-800 hover:text-gray-600')
                        }
                        to="/hr/dashboard"
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText primary="แดชบอร์ด" />
                        </ListItemButton>{' '}
                      </Link> */}
                      {/* <Link
                        className={
                          'text-m uppercase   block ' +
                          (window.location.href.indexOf('/hr/hrcalendar') !== -1
                            ? 'text-green-700 hover:text-green-700'
                            : 'text-gray-800 hover:text-gray-600')
                        }
                        to="/hr/hrcalendar"
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText primary="ตารางงานแผนก" />
                        </ListItemButton>
                      </Link> */}
                      {/* {access?.APPLOVE_WORK && (
                        <Link
                          className={
                            'text-m uppercase   block ' +
                            (window.location.href.indexOf('/hr/work-list') !==
                            -1
                              ? 'text-green-700 hover:text-green-700'
                              : 'text-gray-800 hover:text-gray-600')
                          }
                          to="/hr/work-list"
                        >
                          <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText primary="รายงานการงาน" />
                          </ListItemButton>
                        </Link>
                      )} */}
                      <Link
                        className={
                          'text-m uppercase   block ' +
                          (window.location.href.indexOf('/hr/timestamp') !== -1
                            ? 'text-green-700 hover:text-green-700'
                            : 'text-gray-800 hover:text-gray-600')
                        }
                        to="/hr/timestamp"
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText primary="รายงานการลงเวลา" />
                        </ListItemButton>
                      </Link>

                      <Link
                        className={
                          'text-m uppercase   block ' +
                          (window.location.href.indexOf('/hr/employee') !== -1
                            ? 'text-green-700 hover:text-green-700'
                            : 'text-gray-800 hover:text-gray-600')
                        }
                        to="/hr/employee"
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText primary="จัดการพนักงาน" />
                        </ListItemButton>
                      </Link>
                      <Link
                        className={
                          'text-l uppercase   block ' +
                          (window.location.href.indexOf('/hr/document') !== -1
                            ? 'text-green-700 hover:text-green-700'
                            : 'text-gray-800 hover:text-gray-600')
                        }
                        to="/hr/document"
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          เอกสารใบลา
                        </ListItemButton>
                      </Link>

                      {access?.SETTING && (
                        <div>
                          <ListItemButton
                            onClick={handleSubHrClick}
                            sx={{ pl: 4 }}
                          >
                            <ListItemText primary="ตั้งค่าแผนก" />
                            {openSubHr ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Collapse in={openSubHr} timeout="auto" unmountOnExit>
                            <List>
                              <Link
                                className={
                                  'text-m uppercase   block ' +
                                  (window.location.href.indexOf(
                                    '/hr/groupemployee'
                                  ) !== -1
                                    ? 'text-green-700 hover:text-green-700'
                                    : 'text-gray-800 hover:text-gray-600')
                                }
                                to="/hr/groupemployee"
                              >
                                <ListItemButton sx={{ pl: 6 }}>
                                  <ListItemText primary="จัดการฝ่าย" />
                                </ListItemButton>
                              </Link>
                              <Link
                                className={
                                  'text-m uppercase block ' +
                                  (window.location.href.indexOf(
                                    '/hr/position'
                                  ) !== -1
                                    ? 'text-green-700 hover:text-green-700'
                                    : 'text-gray-800 hover:text-gray-600')
                                }
                                to="/hr/position"
                              >
                                <ListItemButton sx={{ pl: 6 }}>
                                  <ListItemText primary="จัดการตำแหน่งงาน" />
                                </ListItemButton>
                              </Link>
                              <Link
                                className={
                                  'text-m uppercase   block ' +
                                  (window.location.href.indexOf(
                                    '/hr/department'
                                  ) !== -1
                                    ? 'text-green-700 hover:text-green-700'
                                    : 'text-gray-800 hover:text-gray-600')
                                }
                                to="/hr/department"
                              >
                                <ListItemButton sx={{ pl: 6 }}>
                                  <ListItemText primary="จัดการแผนก" />
                                </ListItemButton>
                              </Link>
                              <Link
                                className={
                                  'text-m uppercase   block ' +
                                  (window.location.href.indexOf('/hr/level') !==
                                  -1
                                    ? 'text-green-700 hover:text-green-700'
                                    : 'text-gray-800 hover:text-gray-600')
                                }
                                to="/hr/level"
                              >
                                <ListItemButton sx={{ pl: 6 }}>
                                  <ListItemText primary="จัดการระดับ" />
                                </ListItemButton>
                              </Link>

                              <Link
                                className={
                                  'text-m uppercase   block ' +
                                  (window.location.href.indexOf(
                                    '/hr/setting/leave-type'
                                  ) !== -1
                                    ? 'text-green-700 hover:text-green-700'
                                    : 'text-gray-800 hover:text-gray-600')
                                }
                                to="/hr/setting/leave-type"
                              >
                                <ListItemButton sx={{ pl: 6 }}>
                                  <ListItemText primary="จัดการชนิดการลา" />
                                </ListItemButton>
                              </Link>

                              <Link
                                className={
                                  'text-m uppercase   block ' +
                                  (window.location.href.indexOf(
                                    '/hr/sub-business'
                                  ) !== -1
                                    ? 'text-green-700 hover:text-green-700'
                                    : 'text-gray-800 hover:text-gray-600')
                                }
                                to="/hr/sub-business"
                              >
                                <ListItemButton sx={{ pl: 6 }}>
                                  <ListItemText primary="สังกัด/บริษัทย่อย" />
                                </ListItemButton>
                              </Link>
                            </List>
                          </Collapse>
                        </div>
                      )}
                    </List>
                  </Collapse>
                </div>
              )}

              {/* finance */}
              {me?.userData?.department?.access?.FND && (
                <div>
                  <ListItemButton onClick={handleFiClick}>
                    <ListItemIcon>
                      <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText primary="แผนกการเงิน" />
                    {openFi ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openFi} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <Link
                        className={
                          'text-s uppercase  block ' +
                          (window.location.href.indexOf('/salary/payroll') !==
                          -1
                            ? 'text-yellow-500 hover:text-green-700'
                            : 'text-gray-800 hover:text-gray-600')
                        }
                        to="/salary/payroll"
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText primary="เงินเดือน" />
                        </ListItemButton>{' '}
                      </Link>

                      <Link
                        className={
                          'text-l uppercase   block ' +
                          (window.location.href.indexOf('/salary/doc') !== -1
                            ? 'text-green-700 hover:text-green-700'
                            : 'text-gray-800 hover:text-gray-600')
                        }
                        to="/salary/doc"
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          รายงานประจำปี
                        </ListItemButton>
                      </Link>
                      {setting?.arr?.financeTable ? (
                        <div>
                          <Link
                            className={
                              'text-l uppercase   block ' +
                              (window.location.href.indexOf(
                                '/salary/calendar'
                              ) !== -1
                                ? 'text-orange-700 hover:text-orange-700'
                                : 'text-gray-800 hover:text-gray-600')
                            }
                            to="/salary/calendar"
                          >
                            <ListItemButton sx={{ pl: 4 }}>
                              ตารางการชำระเงิน
                            </ListItemButton>
                          </Link>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </List>
                  </Collapse>{' '}
                </div>
              )}
              {/* {me.userData?.department?.access?.PM && ( */}
              {/* <div>
                <Link
                  className={
                    'text-l uppercase   block ' +
                    (window.location.href.indexOf('/project') !== -1
                      ? 'text-green-700 hover:text-green-700'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to="/project"
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <RecentActorsIcon />
                    </ListItemIcon>
                    โครงการ
                  </ListItemButton>
                </Link>
              </div> */}
              {/* )} */}

              <Link
                className={
                  'text-l uppercase   block ' +
                  (window.location.href.indexOf('/work/colleague') !== -1
                    ? 'text-green-700 hover:text-green-700'
                    : 'text-gray-800 hover:text-gray-600')
                }
                to="/work/colleague"
              >
                <ListItemButton>
                  <ListItemIcon>
                    <PermIdentityIcon />
                  </ListItemIcon>
                  ติดต่อพนักงาน
                </ListItemButton>
              </Link>

              {me?.userData?.department?.access?.PDD && (
                <div>
                  <Link
                    className={
                      'text-l uppercase   block ' +
                      (window.location.href.indexOf('/product') !== -1
                        ? 'text-green-700 hover:text-green-700'
                        : 'text-gray-800 hover:text-gray-600')
                    }
                    to="/product"
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <StoreIcon />
                      </ListItemIcon>
                      สินค้า
                    </ListItemButton>
                  </Link>
                </div>
              )}
              <Link
                className={
                  'text-l uppercase   block ' +
                  (window.location.href.indexOf('/employee/me') !== -1
                    ? 'text-green-700 hover:text-green-700'
                    : 'text-gray-800 hover:text-gray-600')
                }
                to="/employee/me"
              >
                <ListItemButton>
                  <ListItemIcon>
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  ข้อมูลส่วนตัว
                </ListItemButton>
              </Link>

              {/* ADMIN */}
              {me?.userData?.department?.access?.ADM && (
                <div>
                  <ListItemButton onClick={handleADClick}>
                    <ListItemIcon>
                      <AdminPanelSettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="ผู้ดูแลระบบ" />
                    {openAD ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openAD} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <Link
                        className={
                          'text-xs uppercase  font-bold block ' +
                          (window.location.href.indexOf('/admin/dashboard') !==
                          -1
                            ? 'text-green-700 hover:text-green-700'
                            : 'text-gray-800 hover:text-gray-600')
                        }
                        to="/admin/dashboard"
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText primary="ภาพรวมบริษัท" />
                        </ListItemButton>{' '}
                      </Link>
                      {/* <Link
                    className={
                      'text-xs uppercase  font-bold block ' +
                      (window.location.href.indexOf('/admin/employee') !== -1
                        ? 'text-blue-500 hover:text-blue-600'
                        : 'text-gray-800 hover:text-gray-600')
                    }
                    to="/admin/employee"
                  >
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText primary="จัดการพนักงาน" />
                    </ListItemButton>{' '}
                  </Link> */}
                      <Link
                        className={
                          'text-xs uppercase  font-bold block ' +
                          (window.location.href.indexOf('/admin/info') !== -1
                            ? 'text-green-700 hover:text-green-700'
                            : 'text-gray-800 hover:text-gray-600')
                        }
                        to="/admin/info"
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText primary=" จัดการข้อมูลบริษัท" />
                        </ListItemButton>{' '}
                      </Link>
                      <Link
                        className={
                          'text-xs uppercase  font-bold block ' +
                          (window.location.href.indexOf(
                            '/admin/system-admin'
                          ) !== -1
                            ? 'text-green-700 hover:text-green-700'
                            : 'text-gray-800 hover:text-gray-600')
                        }
                        to="/admin/system-admin"
                      >
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemText primary=" จัดการระบบ" />
                        </ListItemButton>{' '}
                      </Link>
                    </List>
                  </Collapse>{' '}
                </div>
              )}
            </List>

            <hr className="my-4 md:min-w-full" />
            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center">
                <div
                  className="text-gray-800 hover:text-gray-600 text-xs uppercase py-3 font-bold block cursor-pointer"
                  onClick={() => {
                    onSignOut();
                  }}
                >
                  <i className="fas fa-fingerprint text-gray-500 mr-2 text-sm"></i>{' '}
                  ออกจากระบบ
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
