export const ReportHeader = (information, renderLogo = true) => ({
  alignment: 'between',
  margin: [20, 20, 20, 0],
  table: {
    widths: [`50%`, `50%`],
    body: [
      [
        {
          image: 'logo',
          width: 100,
          rowSpan: 2,
          margin: [0, 0, 0, 0],
          border: [false, false, false, false],
        },
        { text: '', border: [false, false, false, false] },
      ],
      [{}, { text: '', border: [false, false, false, false] }],
    ],
  },
  styles: {
    header: {
      fontSize: 10,
      bold: true,
      alignment: 'right',
    },
    header1: {
      fontSize: 14,
      bold: true,
    },
  },
});

export default ReportHeader;
