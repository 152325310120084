/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { Button, Card, CardContent, TextField, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import _ from 'lodash';
import { BackButton } from 'components/Button';
import { ViewTitle } from 'components/ViewTitle';
import PropTypes from 'prop-types';
import FormEmployee from '../../components/Forms/FormEmmployee';
import * as action from '../../redux/action';

function EditEmployee({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { handleSubmit, control, reset, setValue } = useForm({
    mode: 'onChange',
  });
  const users = useSelector((state) => state.user);
  const department = useSelector((state) => state.Department);
  const position = useSelector((state) => state.position);
  const group = useSelector((state) => state.group);
  const level = useSelector((state) => state.level);
  const subBusiness = useSelector((state) => state.subBusiness);
  const file = useSelector((state) => state.file);

  const [selectedImage, setSelectedImage] = useState([]);
  const [selectDepartment, setSelectDepartment] = React.useState(
    users?.department
  );
  const [selectGroup, setSelectGroup] = React.useState(users?.group);
  const [selectLevel, setSelectLevel] = React.useState(users?.level);
  const [checked, setChecked] = React.useState(users?.workStatus?.status);
  const [date, setDate] = React.useState(users?.dateStart);
  const [datePass, setDatePass] = React.useState(users?.datePass);

  const [resign, setResign] = React.useState(users?.workStatus?.date);
  const [selectPosition, setSelectPosition] = React.useState('');
  dayjs.extend(buddhistEra);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'certificate',
  });

  const handleChange = (event) => {
    const departments = _.find(department?.arr, { _id: event?._id });
    setValue('department', departments);
  };
  const handleChangePositon = (event) => {
    const positions = _.find(position?.arr, { _id: event?._id });
    setValue('position', positions);
  };
  const handleChangeGroup = (data) => {
    const groups = _.find(group?.arr, { _id: data?._id });
    setValue('group', groups);
  };
  const handleChangeLevel = (event) => {
    const levels = _.find(level?.arr, { _id: event?._id });
    setValue('level', level);
  };

  const handlesubBusiness = (event) => {
    const e = _.find(subBusiness?.arr, { _id: event?._id });
    setValue('subBusiness', e);
  };

  const { control: fileControl, handleSubmit: handleSubmitFile } = useForm();

  const handleUploadFile = async (data) => {
    await dispatch(action.addFile({ ...data, user: id, is_certificate: true }));
    dispatch(
      action.getAllFiles({
        user: id,
        certificate: true,
      })
    );
  };

  const renderLayout = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => <BackButton />;
  const onSubmit = async (data) => {
    const confirm = window.confirm('บันทึกข้อมูล');
    if (confirm) {
      try {
        if (!_.isEmpty(selectedImage)) {
          // eslint-disable-next-line no-param-reassign
          data.image = {
            image: selectedImage[0]?.data_url,
            imageType: 'profile',
            alt: '',
          };
        }
        const payload = {
          ...data,
          dateStart: date,
          datePass,
          // department: selectDepartment,
          position: data?.position?._id,
          level: data?.level?._id,
          group: data?.group?._id,
          workStatus: {
            date: resign,
            status: checked,
            cause: data.cause,
          },
        };

        await dispatch(action.putUser(id, payload));
        await dispatch(action.getAllUser({}));
        history.goBack();
      } catch (error) {
        window.alert(error.message);
        console.error('Error', error);
      }
    }
  };

  function getData() {
    dispatch(action.getOneUser(id));
    dispatch(action.getAllDepartment({}));
    dispatch(action.positionGetAll({}));
    dispatch(action.groupGetAll({}));
    dispatch(action.levelGetAll({}));
    dispatch(action.subBusinessGetAll({}));
    dispatch(
      action.getAllFiles({
        user: id,
        certificate: true,
      })
    );
  }

  useEffect(() => {
    // console.log("Get Data");
    getData();
    return (error) => {
      console.log('err');
    };
  }, []);

  useEffect(() => {
    if (users) {
      setValue('name', users?.name);
      setValue('surname', users?.surname);
      setValue('nickname', users?.nickname);
      setValue('email', users?.email);
      setValue('address', users?.address);
      setValue('tel', users?.tel);
      setDate(users?.dateStart);
      setDatePass(users?.datePass);
      setValue('under', users?.under);
      setValue('position', users?.position);
      setValue('level', users?.level);
      setValue('salary', users?.salary);
      setValue('group', users?.group);
      setValue(
        'department',
        _.isEmpty(users?.department) ? null : users.department
      );
      setValue('note', users?.note);
      setValue('personalID', users?.personalID);
      setValue('subBusiness', users?.subBusiness);
      setValue('employeeId', users?.employeeId);
      setChecked(users?.workStatus?.status);
      setResign(users?.workStatus?.date);
      setSelectLevel(users?.level);
      setValue('certificate', users?.certificate || []);
    }

    return () => {};
  }, [users]);
  const handleEditPSW = () => {
    history.push(`/employee/changepassword/${id}`);
  };

  const handleDeleteFile = (fileId) => {
    dispatch(action.deleteOneFile(fileId));
    dispatch(
      action.getAllFiles({
        user: id,
        certificate: true,
      })
    );
  };
  const renderForm = () => (
    <FormEmployee
      hideUpload={false}
      hideButton={false}
      fields={fields}
      append={append}
      remove={remove}
      users={users}
      Controller={Controller}
      control={control}
      department={department}
      handleChange={handleChange}
      selectDepartment={selectDepartment}
      date={date}
      datePass={datePass}
      setDatePass={setDatePass}
      setDate={setDate}
      position={position}
      subBusiness={subBusiness}
      group={group}
      level={level}
      selectLevel={selectLevel}
      handleChangePositon={handleChangePositon}
      handleChangeGroup={handleChangeGroup}
      handleChangeLevel={handleChangeLevel}
      handlesubBusiness={handlesubBusiness}
      edit
    />
  );
  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <div className="min-h-screen ">
      {renderLayout()}
      {renderBackButton()}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pt-2">
          <Card>
            <CardContent>
              <div>
                {renderForm()}
                <div className=" py-2 ">
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => {
                      handleEditPSW(id);
                    }}
                  >
                    เปลี่ยนรหัสผ่าน
                  </Button>
                </div>
                <div className="p-2 w-full" />
              </div>
              <div className="py-4">
                <div className="flex">
                  <Switch
                    checked={checked}
                    onChange={handleCheck}
                    color="error"
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <h1 className="py-2 font-bold">ลาออก</h1>
                </div>
                {checked && (
                  <div className="lg:flex">
                    <div className="w-1/2">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="วันที่ลาออก"
                          value={resign}
                          onChange={(newValue) => {
                            setResign(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="w-1/2">
                      <Controller
                        name="cause"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={field.value}
                            id="outlined-basic"
                            label="หมายเหตุ"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="border p-4">
                <div className=" py-2 font-bold">เอกสารการอบรม</div>
                {_.map(file?.arr, (each, index) => (
                  <div key={index} className="flex">
                    <div className="pl-2  font-bold">{index + 1}. </div>
                    <a href={each?.url} target="_blank">
                      <h1 className="text-blue-600 font-bold ">
                        {each?.name || each?.file_name}
                      </h1>
                    </a>
                    <div className="pb-4 px-4">
                      <Button
                        color="error"
                        variant="contained"
                        onClick={() => handleDeleteFile(each?._id)}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                ))}
                <form>
                  <div className="font-bold">อัพโหลดเอกสาร</div>
                  <div className="flex justify-between">
                    <Controller
                      name={`file`}
                      control={fileControl}
                      render={({ field }) => (
                        <input
                          type="file"
                          onChange={(e) => {
                            field.onChange(e.target.files?.[0]);
                          }}
                        />
                      )}
                    />
                    <div>
                      <Button
                        variant="contained"
                        type="button"
                        onClick={handleSubmitFile(handleUploadFile)}
                        color="info"
                      >
                        อัพโหลด
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="flex justify-end">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>{' '}
      </form>
    </div>
  );
}
EditEmployee.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
EditEmployee.defaultProps = {
  title: '',
  subtitle: '',
};
export default EditEmployee;
