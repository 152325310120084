import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
// import { text } from 'stream/consumers';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const EmployeeSalaryReport = async (data, information) => {
  // console.log('data', data);
  console.log('information', information);
  console.log('Position', data?.user?.position?.name);
  console.log('data', data);
  console.log('ฝ่าย', data?.user?.group?.name);
  console.log('Department', data?.user?.department?.name);

  const countTaxSSO = () => {
    const sso = data.salary * (data?.taxSSO / 100);
    if (data?.salary >= 15000) {
      return 15000 * (data?.taxSSO / 100);
    }
    return sso;
  };

  const date = new Date();

  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(date);

  const yearRevenue = () =>
    data?.allSalary?.allsalary -
    data?.allSalary?.sumSSO -
    data?.allSalary?.sumEtc;
  const revenue = () => data?.salary + data?.ot + data?.extra + data?.allowance;
  const expenses = () => {
    const sso = countTaxSSO();
    return sso + data?.tax + data?.etc;
  };
  const sumSalary = () => revenue() - expenses();
  const netIncome = () => {
    const sso = countTaxSSO();
    return data?.sumSalary - (sso + data?.tax + data?.etc);
  };
  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 20, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '8',
    },

    info: {
      title: `สลิปเงินเดือน_${data?.user?.name}_${dayjs(data?.date).format(
        'MMMM_BBBB'
      )}`,
    },

    content: [
      {
        margin: [0, 50, 0, 0],
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths: ['70%', '*'],

          body: [
            [
              {
                text: 'บริษัท นิวเทคโนโลยี เอ็นจิเนียริ่ง คอนสตรัคชั่น จำกัด',
                style: 'body2',
                border: [false, false, false, false],
              },

              {
                text:
                  'เดือน-ปี-งวด\t' + `${dayjs(data?.date).format('MMMM-BBBB')}`,
                style: 'body2',
                border: [false, false, false, false],
              },
            ],
            [
              { text: '', border: [false, false, false, false] },

              {
                text:
                  'M-Y-P\t' +
                  `${dayjs(data?.date).locale('en').format('MMMM-YYYY')}`,
                style: 'body2',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [0, 0, 0, 20],
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths: ['50%', '25%', '25%'],

          body: [
            [
              {
                text:
                  'ชื่อ-สกุล/Name\t\t\t' +
                  `${data?.user?.name}  ${data?.user?.surname || '-'}`,
                style: 'body2',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text:
                  'ฝ่าย/Div   \t\t\t\t\t' + `${data?.user?.group?.name || '-'}`,
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: 'แผนก/Dept.\t' + `${data?.user?.department?.name || '-'}`,
                style: 'body2',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text:
                  'ตำแหน่ง/Position\t\t' +
                  `${data?.user?.position?.name || '-'} `,
                style: 'body2',
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
                style: 'body2',
              },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text:
                  'วันที่จ่าย/Paid Date\t' +
                  `${dayjs(data?.datePay).format('DD MMMM BBBB') || '-'}`,
                style: 'body2',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
          ],
        },
      },
      {
        margin: [0, -10, 0, 20],
        table: {
          heights: [15],
          headerRows: 1,
          widths: ['25%', '25%', '25%', '25%'],
          body: [
            [
              {
                text: 'รายได้/Income',
                colSpan: 2,
                style: 'body',
                fillColor: '#d2d4d2',
                border: [true, false, true, true],
              },
              '',
              {
                text: 'รายการหัก/Deduction',
                colSpan: 2,
                style: 'body',
                fillColor: '#d2d4d2',
                border: [true, false, true, true],
              },
              '',
            ],
            [
              {
                text: '\tเงินเดือน/Salary',
                style: 'body2',
                border: [true, true, false, true],
              },
              {
                text: `${
                  data?.salary
                    ?.toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-'
                }`,
                style: 'body1',
                border: [false, true, true, true],
              },
              {
                text: '\tภาษีหัก ณ ที่จ่าย',
                style: 'body2',
                border: [true, true, false, true],
              },
              {
                text: '0.00',
                style: 'body1',
                border: [false, true, true, true],
              },
            ],
            [
              {
                text: '\tค่าแรงวันหยุด',
                style: 'body2',
                border: [true, true, false, true],
                fillColor: '#d2d4d2',
              },
              {
                text: `${
                  data?.ot?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
                  '-'
                }`,
                style: 'body1',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
              {
                text: '\tประกันสังคม',
                style: 'body2',
                border: [true, true, false, true],
                fillColor: '#d2d4d2',
              },
              {
                text: countTaxSSO()
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                style: 'body1',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
            ],
            [
              {
                text: '\tค่าเบี้ยเลี้ยง',
                style: 'body2',
                border: [true, true, false, true],
              },
              {
                text: `${
                  data?.allowance
                    ?.toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-'
                }`,
                style: 'body1',
                border: [false, true, true, true],
              },
              {
                text: '\tรายการหักอื่นๆ',
                style: 'body2',
                border: [true, true, false, true],
              },
              {
                text: `${
                  data?.etc?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
                  '-'
                }`,
                style: 'body1',
                border: [false, true, true, true],
              },
            ],
            [
              {
                text: 'เงินพิเศษอื่นๆ',
                style: 'body2',
                border: [true, true, false, true],
                fillColor: '#d2d4d2',
              },
              {
                text: `${
                  data?.extra?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
                  '0.00'
                }`,
                style: 'body1',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
              {
                text: '',
                style: 'body2',
                border: [true, true, false, true],
                fillColor: '#d2d4d2',
              },
              {
                text: '',
                style: 'body1',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
            ],
            [
              {
                text: '      ',
                style: 'body2',
                border: [true, true, false, true],
                heights: 8,
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
              },
              {
                text: '        ',
                style: 'body2',
                border: [true, true, false, true],
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
              },
            ],
            [
              {
                text: '      ',
                style: 'body2',
                border: [true, true, false, true],
                heights: 8,
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [true, true, false, true],
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
            ],
            [
              {
                text: '      ',
                style: 'body2',
                border: [true, true, false, true],
                heights: 8,
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
              },
              {
                text: '        ',
                style: 'body2',
                border: [true, true, false, true],
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
              },
            ],
            [
              {
                text: '      ',
                style: 'body2',
                border: [true, true, false, true],
                heights: 8,
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [true, true, false, true],
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
            ],
            [
              {
                text: '      ',
                style: 'body2',
                border: [true, true, false, true],
                heights: 8,
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
              },
              {
                text: '        ',
                style: 'body2',
                border: [true, true, false, true],
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
              },
            ],
            [
              {
                text: '      ',
                style: 'body2',
                border: [true, true, false, true],
                heights: 8,
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [true, true, false, true],
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
            ],
            [
              {
                text: '      ',
                style: 'body2',
                border: [true, true, false, true],
                heights: 8,
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
              },
              {
                text: '        ',
                style: 'body2',
                border: [true, true, false, true],
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
              },
            ],
            [
              {
                text: '      ',
                style: 'body2',
                border: [true, true, false, true],
                heights: 8,
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [true, true, false, true],
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
            ],
            [
              {
                text: '      ',
                style: 'body2',
                border: [true, true, false, true],
                heights: 8,
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
              },
              {
                text: '        ',
                style: 'body2',
                border: [true, true, false, true],
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
              },
            ],
            [
              {
                text: '      ',
                style: 'body2',
                border: [true, true, false, true],
                heights: 8,
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [true, true, false, true],
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
            ],
            [
              {
                text: '      ',
                style: 'body2',
                border: [true, true, false, true],
                heights: 8,
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
              },
              {
                text: '        ',
                style: 'body2',
                border: [true, true, false, true],
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
              },
            ],
            [
              {
                text: '      ',
                style: 'body2',
                border: [true, true, false, true],
                heights: 8,
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [true, true, false, true],
                fillColor: '#d2d4d2',
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
            ],
            [
              {
                text: '      ',
                style: 'body2',
                border: [true, true, false, true],
                heights: 8,
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
              },
              {
                text: '        ',
                style: 'body2',
                border: [true, true, false, true],
              },
              {
                text: '        ',
                style: 'body2',
                border: [false, true, true, true],
              },
            ],
            [
              {
                text: 'รวมรายได้/Total Income',
                style: 'body2',
                border: [true, true, false, true],
                fillColor: '#d2d4d2',
              },
              {
                text: `${data?.sumSalary
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
                style: 'body1',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
              {
                text: 'รวมเงินหัก/Total Deduction',
                style: 'body2',
                border: [true, true, false, true],
                fillColor: '#d2d4d2',
              },
              {
                text: expenses()
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                style: 'body1',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
            ],
            [
              { text: '', style: 'body2', border: [true, true, false, true] },
              { text: '', style: 'body2', border: [false, true, true, true] },
              {
                text: 'เงินได้สุทธิ/Net Income',
                style: 'body2',
                border: [true, true, false, true],
                fillColor: '#d2d4d2',
              },
              {
                text: netIncome()
                  ?.toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                style: 'body1',
                border: [false, true, true, true],
                fillColor: '#d2d4d2',
              },
            ],
          ],
        },
      },
      {
        margin: [0, -20, 0, 0],
        table: {
          headerRows: 1,
          widths: ['15%', '35%', '15%', '35%'],
          body: [
            [
              {
                text: 'ลงชื่อผู้จ่าย',
                border: [true, false, true, true],
                style: 'body',
              },
              {
                text: '',
                style: '',
                border: [true, false, true, true],
              },

              {
                text: 'ลงชื่อผู้รับเงิน',
                border: [true, false, true, true],
                style: 'body',
              },
              {
                text: '',
                style: '',
                border: [true, false, true, true],
              },
            ],
            [
              {
                text: 'วันที่',
                border: [true, true, true, true],
                style: 'body',
              },
              {
                text: '',
                style: '',
                border: [true, true, true, true],
              },

              {
                text: 'วันที่',
                border: [true, true, true, true],
                style: 'body',
              },
              {
                text: '',
                style: '',
                border: [true, true, true, true],
              },
            ],
          ],
        },
      },
    ],

    footer: [],

    styles: {
      header: {
        fontSize: 16,
        bold: true,
        alignment: 'center',
      },
      body: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
      body1: {
        fontSize: 12,
        bold: true,
        alignment: 'right',
      },
      body2: {
        fontSize: 12,
        bold: true,
        alignment: 'left',
      },

      body3: {
        fontSize: 12,
        bold: true,
        alignment: 'right',
        decoration: 'underline',
        decorationStyle: 'double',
      },

      footTb: {
        fontSize: 12,
        bold: true,
        alignment: 'right',
        alignment: 'center',
      },
      tab: {
        color: 'white',
      },
    },
    images: {},
  };
  pdfMake.createPdf(documentRef).open();
};
export default EmployeeSalaryReport;
