/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import _ from 'lodash';
import api from '../../utils/functions/api';
import {
  USER_ALL,
  USER_GET,
  ME_ERROR,
  USER_DELETE,
  USER_PUT,
  USER_POST,
  USER_ERROR,
  USER_LOADING,
  ME_PROMPT,
} from '../type';

export function login(data) {
  // console.log("form action", data);
  return async function (dispatch) {
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/login`, {
        username: data.username,
        password: data.password,
      })
      .then((response) => {
        console.log('response: ', response);
        localStorage.setItem('userId', response?.data?.userData?._id);
        localStorage.setItem('Token', response?.data?.token);
        dispatch({ type: ME_PROMPT, payload: response?.data?.userData });
      })
      .catch((err) => {
        console.log('Error on login');
        alert('ชื่อผู้ใช้หรือรหัสผ่านผิด โปรดลองอีกครั้ง');
        dispatch({
          type: ME_ERROR,
          payload: { error: err?.response?.data?.error },
        });
      });
  };
}
export const addUser = (payload) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/user`,
      payload
    );
    dispatch({ type: USER_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({
      type: USER_ERROR,
    });
    throw new Error(error);
  }
};

export const getAllUser = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      page = 1,
      size = '',
      workStatus = '',
      timeStamp = false,
      date = '',
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/user?name=${name}&size=${size}&page=${page}&workStatus=${workStatus}&timeStamp=${timeStamp}&date=${date}`
    );
    if (status === 200) {
      dispatch({ type: USER_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: USER_ERROR });
  }
};
export function getOneUser(id) {
  return async function (dispatch) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/${id}`)
      .then((response) => {
        dispatch({ type: USER_GET, payload: response.data });
        // handle success
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/user/${id}`
    );
    dispatch({ type: USER_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: USER_ERROR });
  }
};

export const putUser = (id, inputPayload) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOADING });
    // const payload = inputPayload;
    // console.log('inputPayload', payload);
    // console.log('Payload.certificate', payload?.certificate);
    // const fileList = _.map(payload?.certificate, (each) => each?.file);
    // const fileListWithoutUndefined = _.filter(
    //   fileList,
    //   (each) => each !== undefined
    // );

    // console.log('File List without undefined', fileListWithoutUndefined);

    // if (!_.isEmpty(fileListWithoutUndefined)) {
    //   let index = 0;
    //   for await (const eachCertificate of payload?.certificate || []) {
    //     console.log(' Certificate--------------', index + 1, eachCertificate);
    //     console.log(`File ${index + 1}`, eachCertificate?.file);
    //     if (eachCertificate?.file) {
    //       console.log('eachCertificate?.file');
    //       const formData = new FormData();
    //       formData.append('file', eachCertificate?.file);

    //       const { data: uploadData } = await api.put(
    //         `${process.env.REACT_APP_API_URL}/upload/file`,
    //         formData,
    //         {
    //           headers: { 'Content-Type': 'multipart/form-data' },
    //         }
    //       );

    //       console.log('Upload Data', index + 1, uploadData);
    //       payload.certificate[index].file = uploadData?.id;
    //       console.log('payLoadssssssssssss', payload);
    //     }

    //     index += 1;
    //   }

    //   console.log('Certificate Payload', payload?.certificate);
    //   const { data } = await api.put(
    //     `${process.env.REACT_APP_API_URL}/user/${id}`,
    //     { ...payload }
    //   );
    //   dispatch({ type: USER_PUT, payload: data });
    // } else {
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/user/${id}`,
      inputPayload
    );

    dispatch({ type: USER_PUT, payload: data });
    // }
  } catch (error) {
    console.error(error);
    dispatch({ type: USER_ERROR });
    throw new Error(error);
  }

  // if (inputPayload.file) {
  //   // Upload File First
  //   const formData = new FormData();
  //   formData.append('file', inputPayload.file);
  //   const { data: uploadData } = await axios.post(
  //     `${process.env.REACT_APP_API_URL}/upload/file`,
  //     formData,
  //     {
  //       headers: { 'Content-Type': 'multipart/form-data' },
  //     }
  //   );

  //   // console.log("Upload Data", uploadData);
  //   await axios.put(`${process.env.REACT_APP_API_URL}/user/${id}`, {
  //     ...inputPayload,
  //     file: uploadData?.id,
  //   });
  //   dispatch({ type: USER_PUT });
  //   console.log('Error this input file');
  // }
  // axios
  //   .put(`${process.env.REACT_APP_API_URL}/user/${id}`, inputPayload)
  //   .then((response) => {
  //     // console.log("response: ", response);
  //     dispatch({ type: USER_PUT });
  //   })
  //   .catch((err) => {
  //     dispatch({ type: USER_ERROR });
  //     console.log('Error in this file');
  //   });
  // console.log(id);
};
