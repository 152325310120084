/* eslint-disable camelcase */
import axios from 'axios';
import {
  SALARY_ALL,
  SALARY_DELETE,
  SALARY_GET,
  SALARY_LOADING,
  SALARY_PUT,
  SALARY_POST,
  SALARY_ERROR,
} from '../type';
import api from '../../utils/functions/api';

export const resetSaraly = () => (dispatch) => {
  dispatch({ type: SALARY_ALL, payload: [] });
};

export const getAllSalary = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      page = 1,
      size = '',
      date = '',
      endDate = '',
      status_month = false,
      orderBy = '',
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/salary?name=${name}&page=${page}&size=${size}&date=${date}&endDate=${endDate}&status=${status_month}&orderBy=${orderBy}`
    );
    if (status === 200) {
      dispatch({ type: SALARY_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SALARY_ERROR });
  }
};

export const getOneSalary = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/salary/${id}`
    );
    if (status === 200) {
      dispatch({ type: SALARY_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    console.log('fffffff');
    dispatch({ type: SALARY_ERROR });
  }
};

export const deleteSalary = (id) => async (dispatch) => {
  try {
    dispatch({ type: SALARY_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/salary/${id}`
    );
    dispatch({ type: SALARY_DELETE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SALARY_ERROR });
  }
};

export const addSalary = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SALARY_LOADING });
    const { data } = await api.post(`${process.env.REACT_APP_API_URL}/salary`, {
      ...payload,
      // eslint-disable-next-line comma-dangle
    });
    dispatch({ type: SALARY_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SALARY_ERROR });
  }
};

export function putSalary(id, ProcessData) {
  return async function (dispatch) {
    if (ProcessData.file) {
      // Upload File First
      const formData = new FormData();
      formData.append('file', ProcessData.file);
      const { data: uploadData } = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload/file`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      // console.log("Upload Data", uploadData);
      await axios.put(`${process.env.REACT_APP_API_URL}/salary/${id}`, {
        ...ProcessData,
        file: uploadData?.id,
      });
      dispatch({ type: SALARY_PUT });
    }
    axios
      .put(`${process.env.REACT_APP_API_URL}/salary/${id}`, ProcessData)
      .then((response) => {
        // console.log("response: ", response);
        dispatch({ type: SALARY_PUT });
        // do something about response
      })
      .catch((err) => {
        // console.error(err);
      });
    // console.log(id);
  };
}
